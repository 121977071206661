body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* h1 { */
  /* position: absolute; */
  /* top: 1rem;
  left: 50rem;
  color: #000000;
  z-index: 10;
  margin: 0;
  padding: 0; */
/* } */
.locate {
  position: absolute;
  top: 3.5rem;
  right: 0.55rem;
  background: none;
  border: none;
  z-index: 10;
  cursor:pointer;
}
.locate img {
  width: 30px;
  cursor: pointer;
}

.button {
  cursor: pointer;
}
